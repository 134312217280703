import { Portal, TooltipContentProps } from "@radix-ui/react-tooltip";
import { ReactNode } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

export function TooltipWrapper({
  children,
  disabled,
  side = "right",
  tooltip,
  wrapperClassName,
  wrapperStyle,
}: {
  children: ReactNode;
  disabled?: boolean;
  side?: TooltipContentProps["side"];
  tooltip: ReactNode;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
}) {
  return (
    <TooltipProvider>
      <Tooltip
        disableHoverableContent={disabled}
        open={disabled ? false : undefined}
      >
        <TooltipTrigger asChild>
          <div className={wrapperClassName} style={wrapperStyle}>
            {children}
          </div>
        </TooltipTrigger>
        <Portal>
          <TooltipContent side={side} className="max-w-64 text-xs font-medium">
            {tooltip}
          </TooltipContent>
        </Portal>
      </Tooltip>
    </TooltipProvider>
  );
}
